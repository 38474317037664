import axios from 'axios'
import { debounce, get, uniqueId } from 'lodash'
import { action, computed, decorate, observable, reaction } from 'mobx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import {
  CODES,
  EVENTS,
  GameType,
  getInitialGameState,
  NOTIFICATION_TYPES,
  POSITIONS,
  TYPES,
} from '../constants'

const CancelToken = axios.CancelToken

let source = CancelToken.source()
const fetch = (options) =>
  axios({ cancelToken: source.token, ...options }).then((r) => r.data)

const time = () => moment().format('ddd MMM D hh:mm:ss.SSS')

// rebuild
export default class GameStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  sessionId = uuid()

  //Game State Mutable Objects
  get gamePk() {
    return this.store.gamePk
  }

  get hasFlexibleLineups() {
    if (this.isMlbGame) {
      if (!this.isSpringTrainingGame && !this.isExhibitionGame) {
        return false
      }
    }

    return true
  }

  get isSpringTrainingGame() {
    return this.store.isGamePage && this.gameType === GameType.SPRING_TRAINING
  }

  get isExhibitionGame() {
    return this.store.isGamePage && this.gameType === GameType.EXHIBITION
  }

  get isMlbGame() {
    return (
      this.store.isGamePage &&
      [this.awayTeam, this.homeTeam].some(
        (team) => team && team.sport && team.sport.id === 1
      )
    )
  }

  // handle pregame locked UI
  @observable _isGameStarted = false

  @computed get isGameStarted() {
    return this._isGameStarted || this.atBatNumber > 1 || this.pitchNumber > 0
  }

  @action startGame() {
    this._isGameStarted = true
  }

  // team options for NGE
  teamOptions = []
  ngeAwayTeamId = ''
  ngeHomeTeamId = ''

  gamedayLineupsEnabled = true
  gumboState = {}
  eventName = ''
  eventSportId = 0

  // boss game state

  balls = 0
  strikes = 0
  outs = 0
  inning = 1
  isTopInning = true
  scheduledInnings = 9
  pitchNumber = 0

  awayPitchCount = 0
  homePitchCount = 0
  hitsAway = 0
  errorsAway = 0
  hitsHome = 0
  errorsHome = 0
  atBatNumber = 1
  pickoffNumber = 0

  awayBatterIdx = 0
  awayPitcherId = null
  homeBatterIdx = 0
  homePitcherId = null
  baseRunners = [false, false, false]
  pitcherOnRubber = false

  gameMode = null

  lastEvent = null
  previousLastEvent = null

  linescore = new Array(9).fill({
    runsAway: null,
    runsHome: null,
  })

  get runsAway() {
    return this.linescore.reduce((sum, inning) => {
      return sum + (inning.runsAway || 0)
    }, 0)
  }

  get runsHome() {
    return this.linescore.reduce((sum, inning) => {
      return sum + (inning.runsHome || 0)
    }, 0)
  }

  awayTeam = {}
  homeTeam = {}

  awayRoster = []
  homeRoster = []

  awayLineup = []
  homeLineup = []

  awayPositions = []
  homePositions = []

  gameType = null
  status = {}
  venue = {}
  datetime = {}
  doubleHeader = null
  gameNumber = null

  //Audit Log
  eventsThisAtBat = []

  pitchData = null

  isTimeout = false
  timeoutDisposer = null
  isCorrection = false
  isPrivate = false
  scheduleEventType = null

  strikeZoneSettings = {}

  // Timeout

  toggleTimeout() {
    this.isTimeout = !this.isTimeout

    if (this.isTimeout) {
      this.pitcherOnRubber = false
      this.gameMode = 1
      if (this.runnerMovement) {
        this.toggleMovement()
      }
      this.eventsThisAtBat.push(EVENTS.TIMEOUT)
      this.triggerEvent(EVENTS.TIMEOUT)
      reaction(
        () => this.bossState,
        (bossState, reaction) => {
          this.isTimeout = false
          reaction.dispose()
        }
      )
    }
  }

  toggleCorrection() {
    this.isCorrection = !this.isCorrection
    this.eventsThisAtBat.push(EVENTS.CORRECTION)
    if (this.isCorrection) {
      this.triggerEvent(EVENTS.CORRECTION)
      this._homeRunButtonEnabled = true
    } else {
      this.triggerEvent(EVENTS.END_CORRECTION)
    }
  }

  // Runner Movement
  runnerMovement = false

  toggleMovement() {
    this.runnerMovement = !this.runnerMovement
    this.eventsThisAtBat.push(EVENTS.RUNNER_MOVEMENT)

    if (this.runnerMovement) {
      this.pitcherOnRubber = false
      this._incrementRunsHotkeyEnabled = true
      this.triggerEvent(EVENTS.RUNNER_MOVEMENT)
    } else {
      this.triggerEvent(EVENTS.END_RUNNER_MOVEMENT)
    }
  }

  set(key, value) {
    this[key] = value
  }

  merge(object) {
    for (let key in object) {
      this.set(key, object[key])
    }
  }

  setPitchData(data) {
    this.pitchData = data
  }

  showDefense = false
  showBattingTeamPitcher = false

  toggleDefense() {
    this.showDefense = !this.showDefense
  }

  setPitchCount(pitches) {
    pitches = parseInt(pitches, 10)
    if (this.isTopInning) {
      this.homePitchCount = pitches
    } else {
      this.awayPitchCount = pitches
    }
  }

  setAtBats(atBats) {
    this.atBatNumber = parseInt(atBats, 10)
  }

  get stringerAtBatNumber() {
    return this.gumboState.atBatNumber
  }

  get stringerPitchNumber() {
    return this.gumboState.pitchNumber
  }

  get stringerPickoffNumber() {
    return this.gumboState.pickoffNumber
  }

  get stringerPitchCount() {
    return this.isTopInning
      ? this.stringerHomePitchCount
      : this.stringerAwayPitchCount
  }

  get stringerAwayPitchCount() {
    return this.gumboState.awayPitchCount
  }

  get stringerHomePitchCount() {
    return this.gumboState.homePitchCount
  }

  get battingLineup() {
    if (this.isTopInning) {
      return this.awayLineup
    } else {
      return this.homeLineup
    }
  }

  get fieldingLineup() {
    if (!this.isTopInning) {
      return this.awayLineup
    } else {
      return this.homeLineup
    }
  }

  get visibleLineup() {
    if (this.showDefense) {
      return this.fieldingLineup
    } else {
      return this.battingLineup
    }
  }

  @computed get sportId() {
    const awaySportId = this.awayTeam?.sport?.id
    const homeSportId = this.homeTeam?.sport?.id

    if (awaySportId === 1) {
      return 1
    }

    if (homeSportId) {
      return homeSportId
    } else if (awaySportId) {
      return awaySportId
    } else if (this.store.isEventPage) {
      //Might not have any teams set for an NGE, so pulling this from the schedule and returning here as last resort
      return this.eventSportId
    }

    return -1
  }

  /**
   * Lineup player components must have stable keys. https://github.com/react-dnd/react-dnd/issues/236
   */
  @computed get visibleLineupLength() {
    return this.visibleLineup.length
  }

  @computed get visibleLineupKeys() {
    return new Array(this.visibleLineupLength).fill(0).map((x) => uniqueId())
  }

  get visibleLineupPlayers() {
    return this.visibleLineup.map((id, index) => {
      const player = this.playerMap[id]
      const code = this.visiblePositions[index]
      const position = POSITIONS[code]

      return {
        ...player,
        code,
        position,
        index,
        type: TYPES.PLAYER,
        key: this.visibleLineupKeys[index],
      }
    })
  }

  get battingRoster() {
    if (this.isTopInning) {
      return this.awayRoster
    } else {
      return this.homeRoster
    }
  }

  get fieldingRoster() {
    if (!this.isTopInning) {
      return this.awayRoster
    } else {
      return this.homeRoster
    }
  }

  get visibleRoster() {
    if (this.showDefense) {
      return this.fieldingRoster
    } else {
      return this.battingRoster
    }
  }

  get battingPositions() {
    if (this.isTopInning) {
      return this.awayPositions
    } else {
      return this.homePositions
    }
  }

  get fieldingPositions() {
    if (!this.isTopInning) {
      return this.awayPositions
    } else {
      return this.homePositions
    }
  }

  get visiblePositions() {
    if (this.showDefense) {
      return this.fieldingPositions
    } else {
      return this.battingPositions
    }
  }

  get battingTeam() {
    if (this.isTopInning) {
      return this.awayTeam
    } else {
      return this.homeTeam
    }
  }

  get fieldingTeam() {
    if (!this.isTopInning) {
      return this.awayTeam
    } else {
      return this.homeTeam
    }
  }

  get visibleTeam() {
    if (this.showDefense) {
      return this.fieldingTeam
    } else {
      return this.battingTeam
    }
  }

  get battingBatterIdx() {
    if (this.isTopInning) {
      return this.awayBatterIdx
    } else {
      return this.homeBatterIdx
    }
  }

  get fieldingBatterIdx() {
    if (!this.isTopInning) {
      return this.awayBatterIdx
    } else {
      return this.homeBatterIdx
    }
  }

  get visibleBatterIdx() {
    if (this.showDefense) {
      return this.fieldingBatterIdx
    } else {
      return this.battingBatterIdx
    }
  }

  get homeBatterId() {
    return this.homeBatterIdx in this.homeLineup
      ? this.homeLineup[this.homeBatterIdx]
      : undefined
  }

  get awayBatterId() {
    return this.awayBatterIdx in this.awayLineup
      ? this.awayLineup[this.awayBatterIdx]
      : undefined
  }

  get currentBatterId() {
    if (this.isTopInning) {
      return this.awayBatterId
    } else {
      return this.homeBatterId
    }
  }

  currentBatSide = 'R'

  get homeBatter() {
    return this.playerMap[this.homeBatterId]
  }

  get awayBatter() {
    return this.playerMap[this.awayBatterId]
  }

  get currentBatter() {
    return this.playerMap[this.currentBatterId]
  }

  /**
   * Supports different strike zones based on batter handedness
   * If multiple zones are returned, find the one with bat side indicated by the operator
   * Otherwise fallback onto the set value.
   *
   * TODO: Eventually we want all of this info to come through in a uniform way
   *  Unfortunately that requires moving off the zones in core.person entirely, which will be a significant lift
   */
  get strikeZoneTop() {
    const batter = this.playerMap[this.currentBatterId]
    if (batter?.strikeZones) {
      const strikeZone = batter?.strikeZones?.find((strikeZone) => {
        return this.currentBatSide === strikeZone.batSide
      })
      return strikeZone?.strikeZoneTop ?? batter.strikeZoneTop
    }

    return batter?.strikeZoneTop
  }

  get strikeZoneBottom() {
    const batter = this.playerMap[this.currentBatterId]
    if (batter?.strikeZones) {
      const strikeZone = batter.strikeZones.find((strikeZone) => {
        return this.currentBatSide === strikeZone.batSide
      })
      return strikeZone?.strikeZoneBottom ?? batter.strikeZoneBottom
    }

    return batter?.strikeZoneBottom
  }

  get currentPitcherId() {
    if (this.isTopInning) {
      return this.homePitcherId
    } else {
      return this.awayPitcherId
    }
  }

  get currentPitchCount() {
    if (this.isTopInning) {
      return this.homePitchCount
    } else {
      return this.awayPitchCount
    }
  }

  get homePitcher() {
    return this.playerMap[this.homePitcherId]
  }

  get awayPitcher() {
    return this.playerMap[this.awayPitcherId]
  }

  get currentPitcher() {
    return this.playerMap[this.currentPitcherId]
  }

  get battingPitcher() {
    if (this.isTopInning) {
      return this.awayPitcher
    } else {
      return this.homePitcher
    }
  }

  get fieldingPitcher() {
    if (this.isTopInning) {
      return this.homePitcher
    } else {
      return this.awayPitcher
    }
  }

  get visiblePitcher() {
    return this.showBattingTeamPitcher
      ? this.battingPitcher
      : this.fieldingPitcher
  }

  get visiblePitcherId() {
    return (this.visiblePitcher || {}).id
  }

  get visiblePitcherRoster() {
    return this.showBattingTeamPitcher
      ? this.battingRoster
      : this.fieldingRoster
  }

  get visiblePitcherTeam() {
    return this.showBattingTeamPitcher ? this.battingTeam : this.fieldingTeam
  }

  toggleVisiblePitcher() {
    this.showBattingTeamPitcher = !this.showBattingTeamPitcher
  }

  get venueId() {
    return this.venue.id
  }

  get trackingVersionId() {
    return this.venue.trackingVersion?.id
  }

  get dayNight() {
    return this.datetime.dayNight
  }

  get playerMap() {
    return this.awayRoster.concat(this.homeRoster).reduce((map, player) => {
      if (player.id) {
        map[player.id] = player
      }

      return map
    }, {})
  }

  /*
   *   Boss Actions
   */

  // Events

  resetEvents() {
    this.eventsThisAtBat.clear()
  }

  // Bases

  toggleBaseRunner(index) {
    let baseRunners = Object.assign([], this.baseRunners)
    baseRunners[index] = !baseRunners[index]
    this.baseRunners.replace(baseRunners)

    if (this.balls === 4 || this.strikes === 3) {
      this._nextBatterHotkeyEnabled = true
    }
  }

  toggleRubber() {
    this.pitcherOnRubber = !this.pitcherOnRubber
    this._homeRunButtonEnabled = false
    this._incrementHitsHotkeyEnabled = false

    if (this.pitcherOnRubber) {
      if (this.runnerMovement) {
        this.toggleMovement()
      }
      this._incrementRunsHotkeyEnabled = false
      this.gameMode = 2
      this.eventsThisAtBat.push(EVENTS.PITCHER_ON_RUBBER)
      this.triggerEvent(EVENTS.PITCHER_ON_RUBBER)
    }
  }

  @action handleHitByPitch() {
    this.pitchThrown()
    this.balls = Math.min(this.balls + 1, 4)
    this.eventsThisAtBat.push(EVENTS.HIT_BY_PITCH)
    this.triggerEvent(EVENTS.HIT_BY_PITCH)
  }

  handleIntentionalWalk() {
    this.eventsThisAtBat.push(EVENTS.INTENTIONAL_WALK)
    this.triggerEvent(EVENTS.INTENTIONAL_WALK)
  }

  /**
   * 
{triggerHomeRun() {
    this._homeRunButtonEnabled = false
    this._incrementHitsHotkeyEnabled = false
    this._incrementRunsHotkeyEnabled = false
    this.eventsThisAtBat.push(EVENTS.HOME_RUN)
    this.incrementHits()
    for (let i = 0; i < this.baseRunners.length; i++) {
      if (this.baseRunners[i]) {
        this.incrementRuns()
        this.toggleBaseRunner(i)
      }
    }
    this.incrementRuns()
    this._nextBatterHotkeyEnabled = true
  }} hit_type 
   */

  handleNGEHit(eventType) {
    this._incrementHitsHotkeyEnabled = false
    this._homeRunButtonEnabled = false
    this.triggerEvent(eventType)
    this.incrementHits()
    this.eventsThisAtBat.push(eventType)

    if (eventType === EVENTS.HOME_RUN) {
      const basesGainedMap = {
        [EVENTS.SINGLE]: 1,
        [EVENTS.DOUBLE]: 2,
        [EVENTS.TRIPLE]: 3,
        [EVENTS.HOME_RUN]: 4,
      }

      const basesGained = basesGainedMap[eventType]
      for (let i = this.baseRunners.length; i >= 0; i--) {
        if (this.baseRunners[i]) {
          if (i + basesGained <= 2) {
            this.toggleBaseRunner(i + basesGained)
          } else {
            this.incrementRuns()
          }

          this.toggleBaseRunner(i)
        }
      }
      if (basesGained <= 3) {
        this.toggleBaseRunner(basesGained - 1)
      } else {
        this.incrementRuns()
      }
    }

    this._nextBatterHotkeyEnabled = true
  }

  handleNGEStrike(strike_type) {
    this.eventsThisAtBat.push(strike_type)
    switch (strike_type) {
      case EVENTS.CALLED_STRIKE:
        return this.incrementValue('sc')
      case EVENTS.SWINGING_STRIKE:
        return this.incrementValue('ss')
      case EVENTS.UNKNOWN_STRIKE:
        return this.incrementValue('su')
      default:
        return
    }
  }

  // Lineup

  _nextBatterHotkeyEnabled = true

  incrementBatterIdx(e) {
    this._incrementRunsHotkeyEnabled = false
    this.endOfPlaySnapshot = this.createGameStateLiteSnapshot(
      EVENTS.END_OF_PLAY
    )
    if (e === true) this._nextBatterHotkeyEnabled = false
    if (this.runnerMovement) this.toggleMovement()
    this.pitchData = null
    this.resetCount()
    this.eventsThisAtBat.push(EVENTS.NEXT_BATTER)
    this.resetEvents()
    this.atBatNumber++

    if (this.showDefense === this.isTopInning) {
      this.homeBatterIdx = (this.homeBatterIdx + 1) % this.homeLineup.length
    } else {
      this.awayBatterIdx = (this.awayBatterIdx + 1) % this.awayLineup.length
    }

    this.triggerEvent(EVENTS.NEXT_BATTER)
  }

  decrementBatterIdx(e) {
    if (e === true) this._nextBatterHotkeyEnabled = false
    if (this.runnerMovement) this.toggleMovement()
    this.pitchData = null
    this.resetCount()
    this.resetEvents()
    this.atBatNumber > 1 && this.atBatNumber--

    if (this.showDefense === this.isTopInning) {
      this.homeBatterIdx = (this.homeBatterIdx + 8) % this.homeLineup.length
    } else {
      this.awayBatterIdx = (this.awayBatterIdx + 8) % this.awayLineup.length
    }
  }

  swapPlayers(a, b) {
    this.set('gamedayLineupsEnabled', false)

    if (this.showDefense == this.isTopInning) {
      let lineup = Object.assign([], this.homeLineup)

      let tmp = lineup[a]
      lineup[a] = lineup[b]
      lineup[b] = tmp

      this.homeLineup = lineup.slice()
    } else {
      let lineup = Object.assign([], this.awayLineup)

      let tmp = lineup[a]
      lineup[a] = lineup[b]
      lineup[b] = tmp

      this.awayLineup = lineup.slice()
    }
  }

  swapPositions(a, b) {
    this.set('gamedayLineupsEnabled', false)

    if (this.showDefense == this.isTopInning) {
      let positions = Object.assign([], this.homePositions)

      let tmp = positions[a]
      positions[a] = positions[b]
      positions[b] = tmp

      this.homePositions.replace(positions)
    } else {
      let positions = Object.assign([], this.awayPositions)

      let tmp = positions[a]
      positions[a] = positions[b]
      positions[b] = tmp

      this.awayPositions.replace(positions)
    }
  }

  setPlayer(index, id) {
    this.set('gamedayLineupsEnabled', false)

    if (this.showDefense == this.isTopInning) {
      let lineup = Object.assign([], this.homeLineup)
      lineup[index] = id
      this.homeLineup = lineup.slice()
    } else {
      let lineup = Object.assign([], this.awayLineup)
      lineup[index] = id
      this.awayLineup = lineup.slice()
    }
  }

  setPosition(index, code) {
    this.set('gamedayLineupsEnabled', false)

    if (this.showDefense == this.isTopInning) {
      let positions = Object.assign([], this.homePositions)
      positions[index] = code
      this.homePositions.replace(positions)
    } else {
      let positions = Object.assign([], this.awayPositions)
      positions[index] = code
      this.awayPositions.replace(positions)
    }
  }

  removePlayerFromLineup(index) {
    this.set('gamedayLineupsEnabled', false)

    if (this.showDefense === this.isTopInning) {
      let lineup = [...this.homeLineup]
      let positions = [...this.homePositions]
      lineup.splice(index, 1)
      positions.splice(index, 1)
      this.homeLineup = lineup.slice()
      this.homePositions.replace(positions)
    } else {
      let lineup = [...this.awayLineup]
      let positions = [...this.awayPositions]
      lineup.splice(index, 1)
      positions.splice(index, 1)
      this.awayLineup = lineup.slice()
      this.awayPositions.replace(positions)
    }
  }

  addPlayerToLineup(index) {
    this.set('gamedayLineupsEnabled', false)

    if (this.showDefense === this.isTopInning) {
      let lineup = [...this.homeLineup]
      let positions = [...this.homePositions]
      const player = this.homeRoster.find(
        (player) => !this.homeLineup.includes(player.id)
      )
      if (player) {
        lineup.push(player.id)
        positions.push(13)
      }
      this.homeLineup = lineup.slice()
      this.homePositions.replace(positions)
    } else {
      let lineup = [...this.awayLineup]
      let positions = [...this.awayPositions]
      const player = this.awayRoster.find(
        (player) => !this.awayLineup.includes(player.id)
      )
      if (player) {
        lineup.push(player.id)
        positions.push(13)
      }
      this.awayLineup = lineup.slice()
      this.awayPositions.replace(positions)
    }
  }

  // pitcher

  setPitcher(id) {
    this.set('gamedayLineupsEnabled', false)
    this.set('gameMode', 1)

    if (this.isTopInning) {
      this.homePitchCount = 0
      this.homePitcherId = id
    } else {
      this.awayPitchCount = 0
      this.awayPitcherId = id
    }

    this.triggerEvent(EVENTS.SUBSTITUTION)
  }

  setVisiblePitcher(id) {
    this.set('gamedayLineupsEnabled', false)
    this.set('gameMode', 1)

    if (this.showBattingTeamPitcher === this.isTopInning) {
      this.awayPitchCount = 0
      this.awayPitcherId = id
    } else {
      this.homePitchCount = 0
      this.homePitcherId = id
    }

    this.triggerEvent(EVENTS.SUBSTITUTION)
  }

  setBatter(id) {
    this.set('gamedayLineupsEnabled', false)

    if (this.showDefense == this.isTopInning) {
      let lineup = Object.assign([], this.homeLineup)
      lineup[this.visibleBatterIdx] = id
      this.homeLineup = lineup.slice()
    } else {
      let lineup = Object.assign([], this.awayLineup)
      lineup[this.visibleBatterIdx] = id
      this.awayLineup = lineup.slice()
    }

    this.triggerEvent(EVENTS.SUBSTITUTION)
  }

  //Scoreboard

  _incrementHitsHotkeyEnabled = false

  incrementHits() {
    this.isTopInning ? this.hitsAway++ : this.hitsHome++
    this.triggerEvent(EVENTS.HIT)
    this.eventsThisAtBat.push(EVENTS.HIT)
    this._incrementHitsHotkeyEnabled = false
    this._homeRunButtonEnabled = false
  }

  decrementHits() {
    this.isTopInning
      ? this.hitsAway > 0 && this.hitsAway--
      : this.hitsHome > 0 && this.hitsHome--
  }

  _incrementRunsHotkeyEnabled = false

  incrementRuns() {
    this._homeRunButtonEnabled = false
    if (this.isTopInning) {
      this.linescore[this.inning - 1].runsAway++
    } else {
      this.linescore[this.inning - 1].runsHome++
    }
    this.eventsThisAtBat.push(EVENTS.RUN)
    this.triggerEvent(EVENTS.RUN)
    if (this.baseRunners.filter(Boolean).length === 3) {
      if (this.balls === 4) {
        this._nextBatterHotkeyEnabled = true
      }
    }
  }

  decrementRuns() {
    if (this.isTopInning) {
      this.linescore[this.inning - 1].runsAway > 0 &&
        this.linescore[this.inning - 1].runsAway--
    } else {
      this.linescore[this.inning - 1].runsHome > 0 &&
        this.linescore[this.inning - 1].runsHome--
    }
  }

  _nextFrameHotkeyEnabled = false

  incrementInning() {
    if (this.runnerMovement) this.toggleMovement()
    this.endOfPlaySnapshot = this.createGameStateLiteSnapshot(
      EVENTS.END_OF_PLAY
    )
    this.resetCount()
    this.outs = 0
    this.baseRunners = [false, false, false]

    this.gameMode = 1

    let idx = this.inning - 1
    let linescore = Object.assign([], this.linescore)

    if (this.isTopInning) {
      if (!this.linescore[idx].runsAway) {
        this.linescore[idx].runsAway = 0
      }
    } else {
      if (!this.linescore[idx].runsHome) {
        this.linescore[idx].runsHome = 0
      }
      this.inning++

      if (!linescore[idx + 1]) {
        linescore.push({
          num: this.inning,
          runsAway: null,
          runsHome: null,
        })
      }
    }

    this.isTopInning = !this.isTopInning
    this.linescore.replace(linescore)
    this.triggerEvent(EVENTS.NEXT_FRAME)
  }

  decrementInning() {
    if (this.runnerMovement) this.toggleMovement()
    this.resetCount()
    this.outs = 0
    this.baseRunners = [false, false, false]

    let idx = this.inning - 1
    let linescore = Object.assign([], this.linescore)

    if (!this.isTopInning) {
      linescore[idx].runsHome = null
      this.isTopInning = !this.isTopInning
    } else {
      linescore[idx].runsAway = null

      if (this.inning > 9) {
        linescore = linescore.slice(0, idx)
      }

      if (this.inning != 1) {
        this.inning--
        this.isTopInning = !this.isTopInning
      }
    }

    this.linescore.replace(linescore)
  }

  // Count

  resetCount() {
    this.balls = 0
    this.strikes = 0
    this.pitchNumber = 0
    this.pickoffNumber = 0
    this.eventsThisAtBat.clear()
  }

  pitchThrown(pitchType) {
    const isAutoPitch = [EVENTS.AUTO_BALL, EVENTS.AUTO_STRIKE].includes(
      pitchType
    )
    if (!isAutoPitch) {
      this.incrementPitchCount()
    }
    this.pitcherOnRubber = false
    this.pitchData = null

    if (!pitchType) {
      return
    }

    if (this.runnerMovement) {
      this._incrementRunsHotkeyEnabled = true
    } else if (pitchType === EVENTS.IN_PLAY) {
      this._incrementRunsHotkeyEnabled = true
    } else if (pitchType === EVENTS.BALL && this.balls === 4) {
      this._incrementRunsHotkeyEnabled = true
    }

    this.triggerEvent(pitchType)
  }

  endOfPlayTimeout = null
  endOfPlaySnapshot = null

  triggerEvent(eventType) {
    if (
      this.isCorrection &&
      ![EVENTS.CORRECTION, EVENTS.END_CORRECTION].includes(eventType)
    )
      return

    if ([EVENTS.INTENTIONAL_WALK, EVENTS.HIT_BY_PITCH].includes(eventType)) {
      this.pitcherOnRubber = false
      if (this.baseRunners.every((baseRunner) => !!baseRunner)) {
        this._incrementRunsHotkeyEnabled = true
      }
    }

    console.info(`[${time()}] Triggered Event: ${eventType}`)

    /*
     *   triggers data dump to partners
     *   pitchType here is actually the pitch call (B, S, F, etc.)
     */

    if (
      [
        EVENTS.BALL,
        EVENTS.STRIKE,
        EVENTS.CALLED_STRIKE,
        EVENTS.SWINGING_STRIKE,
        EVENTS.UNKNOWN_STRIKE,
        EVENTS.FOUL,
        EVENTS.IN_PLAY,
      ].includes(eventType)
    ) {
      this.updatePitchcastPitchType(eventType)
    }

    /**
     * End of play logic starts here
     */

    const endOfPlayCushion = 1000
    if (![EVENTS.NEXT_BATTER, EVENTS.NEXT_FRAME].includes(eventType)) {
      // create a snapshot to be sent on next batter or next frame without the updated game state (ie. new at bat number, new batter, etc)
      this.endOfPlaySnapshot = this.createGameStateLiteSnapshot(
        EVENTS.END_OF_PLAY
      )
    }

    // 1. ball, strike or pickoff without runner movement
    if (
      [
        EVENTS.BALL,
        EVENTS.STRIKE,
        EVENTS.CALLED_STRIKE,
        EVENTS.SWINGING_STRIKE,
        EVENTS.UNKNOWN_STRIKE,
        EVENTS.PICKOFF,
      ].includes(eventType) &&
      !this.runnerMovement
    ) {
      this.endOfPlayTimeout = setTimeout(() => {
        this.sendGameStateLiteSnapshot(this.endOfPlaySnapshot)
      }, endOfPlayCushion)
    }

    // 1a. cancel previous condition if runner movement starts within the window of the timeout; end of play will trigger on end of runner movement
    if (eventType === EVENTS.RUNNER_MOVEMENT) {
      clearTimeout(this.endOfPlayTimeout)
    }

    // 2. end of runner movement, foul, intentional walk
    if (
      [
        EVENTS.END_RUNNER_MOVEMENT,
        EVENTS.FOUL,
        EVENTS.INTENTIONAL_WALK,
      ].includes(eventType)
    ) {
      this.endOfPlayTimeout = setTimeout(() => {
        this.sendGameStateLiteSnapshot(this.endOfPlaySnapshot)
      }, endOfPlayCushion)
    }

    // 3. next batter, next frame
    if ([EVENTS.NEXT_BATTER, EVENTS.NEXT_FRAME].includes(eventType)) {
      const { pitch_number, pickoff_number } = this.endOfPlaySnapshot || {}

      if ([pitch_number, pickoff_number].some((n) => n > 0)) {
        clearTimeout(this.endOfPlayTimeout)
        this.sendGameStateLiteSnapshot(this.endOfPlaySnapshot)
      }

      setTimeout(() => {
        this.sendGameStateLiteWithEvent(eventType)
      }, endOfPlayCushion)
      return
    }

    /**
     * Next batter hotkey logic starts here
     */

    if (eventType === EVENTS.PITCHER_ON_RUBBER && this.pitcherOnRubber) {
      this._nextBatterHotkeyEnabled = false
    } else if (eventType === EVENTS.OUT && this.strikes === 3) {
      this._nextBatterHotkeyEnabled = true
    } else if (
      [EVENTS.IN_PLAY, EVENTS.INTENTIONAL_WALK, EVENTS.HIT_BY_PITCH].includes(
        eventType
      )
    ) {
      this._nextBatterHotkeyEnabled = true
    }

    if (this.isConnected && !!eventType) {
      this.sendGameStateLiteWithEvent(eventType)
    }
  }

  _homeRunButtonEnabled = false
  triggerHomeRun() {
    this._homeRunButtonEnabled = false
    this._incrementHitsHotkeyEnabled = false
    this._incrementRunsHotkeyEnabled = false
    this.eventsThisAtBat.push(EVENTS.HOME_RUN)
    this.incrementHits()
    for (let i = 0; i < this.baseRunners.length; i++) {
      if (this.baseRunners[i]) {
        this.incrementRuns()
        this.toggleBaseRunner(i)
      }
    }
    this.incrementRuns()
    this._nextBatterHotkeyEnabled = true
  }

  removePitch() {
    if (this.isTopInning) {
      this.homePitchCount > 0 && this.homePitchCount--
    } else {
      this.awayPitchCount > 0 && this.awayPitchCount--
    }
    this.pitchNumber > 0 && this.pitchNumber--
  }

  @action handleAutomaticStrike() {
    if (this.strikes < 3) {
      this.strikes++
      this.pitchThrown(EVENTS.AUTO_STRIKE)
    }
  }

  @action handleAutomaticBall() {
    if (this.balls < 4) {
      this.balls++
      this.pitchThrown(EVENTS.AUTO_BALL)
      if (this.balls === 4) {
        this._incrementRunsHotkeyEnabled = true
      }
    }
  }

  handleClick(code) {
    this._homeRunButtonEnabled = false
    this.eventsThisAtBat.push(CODES[code])

    if (code.indexOf('shift') === -1) {
      this.incrementValue(code)
    } else {
      this.decrementValue(code.charAt(code.indexOf('+') + 1))
    }

    if (this.runnerMovement && CODES[code] === EVENTS.FOUL) {
      this.toggleMovement()
    }
  }

  incrementValue(code) {
    if (typeof code !== 'string' || !code) {
      return
    }

    switch (code[0]) {
      case 'b':
        if (this.balls < 4) {
          this.balls++
          this.pitchThrown(EVENTS.BALL)
          if (this.balls === 4) {
            this._incrementRunsHotkeyEnabled = true
          }
        }
        break
      case 's':
        if (this.strikes < 3) {
          this.strikes++
          if (code === 's') {
            this.pitchThrown(EVENTS.STRIKE)
          } else if (code === 'sc') {
            this.pitchThrown(EVENTS.CALLED_STRIKE)
          } else if (code === 'ss') {
            this.pitchThrown(EVENTS.SWINGING_STRIKE)
          } else if (code === 'su') {
            this.pitchThrown(EVENTS.UNKNOWN_STRIKE)
          }
        }
        break
      case 'f':
        this.strikes < 2 && this.strikes++
        this.pitchThrown(EVENTS.FOUL)
        break
      case 'v':
        this.handleAutomaticBall()
        break
      case 'k':
        this.handleAutomaticStrike()
        break
      case 'o':
        if (this.outs < 3) {
          this.outs++
          this.triggerEvent(EVENTS.OUT)
        }
        break
      case 'r':
        this.incrementRuns()
        break
      case 'e':
        this.isTopInning ? this.errorsHome++ : this.errorsAway++
        this.triggerEvent(EVENTS.ERROR)
        break
      case 'h':
        this.incrementHits()
        break
      case 'p':
        if (code === 'p') {
          this.pitchThrown(EVENTS.IN_PLAY)
          this._incrementHitsHotkeyEnabled = true
          this._homeRunButtonEnabled = true
        }
        break
      case 'a':
        this.atBatNumber++
        break
      case 'z':
        this.pitcherOnRubber = false
        this.pickoffNumber++
        this.triggerEvent(EVENTS.PICKOFF)
        break
      default:
        break
    }
  }

  @action incrementPitchCount() {
    this.isTopInning ? this.homePitchCount++ : this.awayPitchCount++
    this.pitchNumber++
  }

  decrementValue(code) {
    switch (code) {
      case 'b':
        this.balls > 0 && this.balls--
        this.decrementValue('p')
        break
      case 's':
        this.strikes > 0 && this.strikes--
        this.decrementValue('p')
        break
      case 'o':
        this.outs > 0 && this.outs--
        break
      case 'r':
        this.decrementRuns()
        break
      case 'e':
        this.isTopInning
          ? this.errorsHome > 0 && this.errorsHome--
          : this.errorsAway > 0 && this.errorsAway--
        break
      case 'h':
        this.isTopInning
          ? this.hitsAway > 0 && this.hitsAway--
          : this.hitsHome > 0 && this.hitsHome--
        break
      case 'p':
        this.removePitch()
        break
      case 'a':
        this.atBatNumber--
        break
      case 'z':
        if (this.pickoffNumber > 0) {
          this.pickoffNumber--
        }
        break
      default:
        break
    }
  }

  setLinescore(options) {
    let { id, inning, value } = options

    value = parseInt(value, 10)

    if (isNaN(value)) {
      value = null
    }

    let linescore = Object.assign([], this.linescore)

    if (id.includes('errors') || id.includes('hits')) {
      this[id] = value
    }

    if (id.includes('runs')) {
      linescore[inning - 1][id] = value
    }

    this.linescore.replace(linescore)
  }

  setState(gameState) {
    Object.assign(this, gameState)
  }

  /*
   *   Remote Boss State
   *   - Fetched from S3 on game load.
   *   - Received over AMQ via WebSocket when remote Boss connected.
   */

  _bossState = null

  /*
   *   Local Boss State
   *   - Saved to localStorage.
   *   - Saved to S3 and sent over AMQ when connected.
   */

  get bossState() {
    let {
      gamedayLineupsEnabled,
      balls,
      strikes,
      outs,
      inning,
      isTopInning,
      linescore,
      atBatNumber,
      pitchNumber,
      pickoffNumber,
      awayPitcherId,
      awayPitchCount,
      homePitcherId,
      homePitchCount,
      awayBatterIdx,
      homeBatterIdx,
      hitsAway,
      hitsHome,
      errorsAway,
      errorsHome,
      baseRunners,
      awayLineup,
      homeLineup,
      awayPositions,
      homePositions,
      eventsThisAtBat,
      pitcherOnRubber,
      isTimeout,
      runnerMovement,
      currentBatSide,
      isCorrection,
      gameMode,
      trackingVersionId,
    } = this

    baseRunners = Object.assign([], baseRunners)

    linescore = Object.assign([], linescore).map((i) => Object.assign({}, i))

    return {
      gamedayLineupsEnabled,
      balls,
      strikes,
      outs,
      inning,
      linescore,
      isTopInning,
      atBatNumber,
      pitchNumber,
      pickoffNumber,
      awayPitcherId,
      awayPitchCount,
      homePitcherId,
      homePitchCount,
      awayBatterIdx,
      homeBatterIdx,
      hitsAway,
      hitsHome,
      errorsAway,
      errorsHome,
      baseRunners,
      awayLineup,
      homeLineup,
      awayPositions,
      homePositions,
      eventsThisAtBat,
      pitcherOnRubber,
      isTimeout,
      runnerMovement,
      currentBatSide,
      isCorrection,
      gameMode,
      trackingVersionId,
    }
  }

  saveBossStateToLocalStorage() {
    let { bossState, gamePk, gamedayLineupsEnabled } = this

    if (gamedayLineupsEnabled) {
      return
    }

    for (let key of Object.keys(localStorage)) {
      if (key === 'sessionId') {
        continue
      }

      if (!moment(key, moment.ISO_8601, true).isValid()) {
        localStorage.removeItem(key)
      }

      try {
        let state = JSON.parse(localStorage.getItem(key))

        if (state.gamePk !== this.gamePk) {
          localStorage.removeItem(key)
        }
      } catch (err) {
        localStorage.removeItem(key)
      }
    }

    let keys = Object.keys(localStorage).sort().reverse()

    for (let i = 9; i < keys.length; i++) {
      localStorage.removeItem(keys[i])
    }

    let key = moment().toISOString()
    let string = JSON.stringify({
      gamePk,
      bossState,
    })

    localStorage.setItem(key, string)
  }

  lastReceivedCorrectionTs
  receiveCorrection(bossState) {
    const cooldown = 5 * 1000
    const time = Date.now()

    if (
      this.lastReceivedCorrectionTs &&
      time - this.lastReceivedCorrectionTs < cooldown
    ) {
      console.warn(
        'Received admin correction during cooldown. Skipping. Remaining cooldown: %s',
        time - this.lastReceivedCorrectionTs
      )
      return
    }

    this.lastReceivedCorrectionTs = time

    this.store.flash(NOTIFICATION_TYPES.REMOTE_UPDATE, 3)

    const beforeSnapshot = this.createGameStateLiteSnapshot(EVENTS.CORRECTION)
    this.sendGameStateLiteSnapshot(beforeSnapshot)

    const { isCorrection, trackingVersionId, ...rest } = bossState

    this.setState(rest)

    const afterSnapshot = this.createGameStateLiteSnapshot(
      EVENTS.END_CORRECTION
    )
    this.sendGameStateLiteSnapshot(afterSnapshot)
  }

  cancelCorrections() {
    this.setState(this._bossState)
    this.set('isCorrecting', false)
  }

  sendCorrections() {
    this._bossState = this.bossState
    this.store.flash(NOTIFICATION_TYPES.UPDATE_SENT, 2)
    return this.sendBossState(true)
  }

  sendBossState(isAdminCorrection) {
    if (!this.isConnected && !this.isCorrecting) {
      return
    }

    let { bossState, gamePk } = this

    fetch({
      url: '/api/game/bossState',
      method: 'post',
      data: {
        bossState: {
          ...bossState,
          isAdminCorrection,
        },
        venueId: this.venueId,
        gamePk,
      },
    })
      .then(() => {
        // console.info('Sent:BossState:AMQ!')
        // console.info('Saved:BossState:S3!')
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(
        action(() => {
          if (isAdminCorrection) {
            this.isCorrecting = false
          }
        })
      )
  }

  createGameStateLiteSnapshot(last_event) {
    return Object.assign({}, this.gameStateLite, {
      last_event,
    })
  }

  get gameStateLite() {
    /*
     *   data contract
     *   https://wiki.mlbam.com/display/BD/Live+Game+State+Light+Feed+-+ActiveMQ
     */

    let {
      gamePk: game_pk,
      atBatNumber: at_bat_number,
      pitchNumber: pitch_number,
      inning,
      balls,
      strikes,
      outs,
      currentPitcherId,
      currentPitcher = {},
      currentBatterId,
      currentBatSide,
      strikeZoneTop: strike_zone_top,
      strikeZoneBottom: strike_zone_bottom,
      currentPitchCount,
      errorsAway: errors_away,
      errorsHome: errors_home,
      hitsAway: hits_away,
      hitsHome: hits_home,
      runsAway: runs_away,
      runsHome: runs_home,
      linescore,
      isTopInning,
      awayLineup,
      homeLineup,
      awayPositions,
      homePositions,
      pitcherOnRubber,
      runnerMovement,
      isTimeout,
      gameMode: game_mode,
      baseRunners,
      pickoffNumber,
      venueId: venue_id,
      isCorrection,
      awayTeam = {},
      homeTeam = {},
      isPrivate,
      scheduleEventType,
      trackingVersionId: tracking_version_id,
    } = this

    const {
      strikeZoneWidth: strike_zone_width,
      strikeZoneDepth: strike_zone_depth,
      strikeZoneAnalyze: strike_zone_analyze,
      strikeZoneCornerRadius: strike_zone_corner_radius,
      strikeZoneFlat: strike_zone_flat,
      strikeZoneRounded: strike_zone_rounded,
      strikeZoneShowInLiveFeed: strike_zone_show_in_live_feed,
    } = this.strikeZoneSettings

    const { id: away_id } = awayTeam

    const { id: home_id } = homeTeam

    return {
      game_pk,
      venue_id,
      at_bat_number,
      batter: currentBatterId,
      strike_zone_top,
      strike_zone_bottom,
      strike_zone_width,
      strike_zone_depth,
      strike_zone_analyze,
      strike_zone_show_in_live_feed,
      strike_zone_corner_radius,
      strike_zone_flat,
      strike_zone_rounded,
      balls,
      game_mode, // 0 - batting practice, 1 - warm up, 2 - live, 3 - off
      message_type: 'game_state_lite',
      pitch_number,
      outs,
      half_inning: isTopInning ? 'Top' : 'Bot',
      inning,
      strikes,
      pitcher: currentPitcherId,
      pitcher_pitch_count: currentPitchCount,
      runs_away,
      runs_home,
      hits_away,
      hits_home,
      errors_away,
      errors_home,
      // "last_event" : "", // see appendix for event types. will be blank for heart-beat and non-event based messages (ie subs)
      linescore: linescore.map((inning, i) => {
        return {
          inning: i + 1,
          runs_away: inning.runsAway,
          runs_home: inning.runsHome,
        }
      }),
      away_lineup: awayLineup.map((playerId, i) => {
        let player = this.playerMap[playerId] || {}
        let { jerseyNumber: jersey_number } = player

        return {
          player_id: playerId,
          position: awayPositions[i],
          jersey_number,
        }
      }),
      home_lineup: homeLineup.map((playerId, i) => {
        let player = this.playerMap[playerId] || {}
        let { jerseyNumber: jersey_number } = player

        return {
          player_id: playerId,
          position: homePositions[i],
          jersey_number,
        }
      }),
      away_id,
      home_id,
      pitcher_on_rubber: pitcherOnRubber,
      timeout: isTimeout,
      runner_movement: runnerMovement,
      runner_on_1b: !!baseRunners[0],
      runner_on_2b: !!baseRunners[1],
      runner_on_3b: !!baseRunners[2],
      bat_side: currentBatSide || '',
      pitch_hand: currentPitcher.throws || '',
      pickoff_number: pickoffNumber,
      is_correction: isCorrection ? true : undefined,
      is_private: isPrivate,
      tracking_version_id,
    }
  }

  async sendGameOver() {
    const gameStateLite = {
      ...this.gameStateLite,
      game_mode: 3,
      last_event: EVENTS.GAME_OVER,
    }

    const id = uuid()
    this._requests[id] = true

    await this.sendGameStateLiteSnapshot(gameStateLite)

    await this.disconnect()

    delete this._requests[id]
    this.store.router.push('/')
  }

  sendGameStateLiteSnapshot(gameStateLite) {
    return this._sendGameStateLite(gameStateLite)
  }

  sendGameStateLite() {
    const gameStateLite = this.createGameStateLiteSnapshot()
    return this._sendGameStateLite(gameStateLite)
  }

  gameStateLiteHeartbeatTimeout = null
  sendGameStateLiteHeartbeat() {
    const gameStateLite = this.createGameStateLiteSnapshot()
    return this._sendGameStateLite(gameStateLite, true)
  }

  sendGameStateLiteWithEvent(eventType) {
    const gameStateLite = this.createGameStateLiteSnapshot(eventType)
    return this._sendGameStateLite(gameStateLite)
  }

  _sendGameStateLite(gameStateLite, heartbeat) {
    clearTimeout(this.gameStateLiteHeartbeatTimeout)
    const { isEventPage } = this.store
    const {
      isConnected,
      venueId,
      gamePk,
      gameMode,
      sportId,
      trackingVersionId,
    } = this

    if (!isConnected || gameMode == null || !venueId || !gamePk) {
      return
    }

    const { last_event: eventType } = gameStateLite

    if (gameMode === 3 && eventType !== EVENTS.GAME_OVER) {
      return
    }

    if (eventType) console.info(`[${time()}] Sending Event: ${eventType}`)

    return fetch({
      url: `/api/game/${sportId}/${isEventPage ? 'nge/' : ''}gameStateLite`,
      method: 'post',
      data: {
        gameStateLite,
        gamePk,
        venueId,
        heartbeat,
        trackingVersionId,
      },
    })
      .then(({ gameStateLite }) => {
        if (eventType) console.info(`[${time()}] Sent Event: ${eventType}`)
        this.saveGameStateLite(gamePk, gameStateLite)
      })
      .catch((err) => {
        if (eventType) {
          console.error(`[${time()}] Error Sending Event: ${eventType}`)
        }

        if (err.response?.data?.gameStateLite) {
          this.saveGameStateLite(gamePk, err.response?.data?.gameStateLite)
        }

        console.error(err)
      })
      .finally(
        action(() => {
          clearTimeout(this.gameStateLiteHeartbeatTimeout)
          this.gameStateLiteHeartbeatTimeout = setTimeout(() => {
            this.sendGameStateLiteHeartbeat()
          }, 10 * 1000)
        })
      )
  }

  async saveGameStateLite(gamePk, gameStateLite) {
    try {
      await fetch({
        url: '/api/game/saveGameStateLite',
        method: 'post',
        data: {
          gamePk,
          gameStateLite,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  // connection

  connect() {
    let id = uuid()

    this._requests[id] = true

    return fetch({
      url: '/api/game/connect',
      method: 'post',
      data: {
        gamePk: this.gamePk,
        venueId: this.venueId,
        noradGameState: this.noradGameState,
      },
    })
      .then(
        action((connections) => {
          this.store.connections.replace(connections)
        })
      )
      .catch((error) => {
        console.error(error)
        const message = error.response?.data?.error
        this.store.flash(NOTIFICATION_TYPES.DUPLICATE_OPERATOR, 10, message)
      })
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  disconnect() {
    let id = uuid()

    this._requests[id] = true

    let connections = this.store.connections.slice().filter((connection) => {
      if (
        connection.gamePk == this.gamePk &&
        connection.username == this.store.auth.username
      ) {
        return false
      }
      return true
    })

    this.store.connections.replace(connections)

    return fetch({
      url: '/api/game/disconnect',
      method: 'delete',
      data: {
        gamePk: this.gamePk,
      },
    })
      .then(
        action((connections) => {
          this.store.connections.replace(connections)
        })
      )
      .catch((error) => {
        console.error(error)
      })
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  get isConnected() {
    const connection = this.store.connectionMap[this.gamePk]

    if (!connection) return false

    const { username, statusIndicator = {} } = connection
    const { sessionId } = statusIndicator

    return username === this.store.auth.username && sessionId === this.sessionId
  }

  isObserving = false
  isCorrecting = false

  get canCorrect() {
    if (!this.isObserving || !this._bossState) {
      return false
    }

    if (this._bossState.isCorrection) {
      return false
    }

    return true
  }

  get currentBossOperator() {
    let connection = this.store.connectionMap[this.gamePk]
    return connection ? connection.username : null
  }

  get canObserve() {
    if (!this.store.auth.isSuperUser) {
      return false
    }

    if (!this._bossState) {
      return false
    }

    if (
      !this.currentBossOperator ||
      this.currentBossOperator === this.store.auth.username
    ) {
      return false
    }

    if (this.isConnected) {
      return false
    }

    return true
  }

  fetch() {
    let id = uuid()

    this._requests[id] = true

    const url = this.store.isEventPage ? '/api/event' : '/api/game'

    return fetch({
      url,
      params: this.params,
    })
      .then(
        action((data) => {
          let {
            name,
            game = {},
            status = {},
            venue = {},
            datetime = {},
            awayTeam = {},
            homeTeam = {},
            awayRoster = [],
            homeRoster = [],
            gumboState = {},
            bossState,
            publicFacing,
            teamOptions = [],
            strikeZoneSettings = {},
            sportId,
          } = data

          const { isAmqPublic = false } = strikeZoneSettings

          this.gameType = game.type
          this.status = status
          this.venue = venue
          this.datetime = datetime
          this.awayTeam = awayTeam
          this.homeTeam = homeTeam
          this.awayRoster = awayRoster
          this.homeRoster = homeRoster
          this.gumboState = gumboState
          this.eventName = name
          this.isPrivate = publicFacing || isAmqPublic ? false : true //Invert here to match "is_private"
          this.scheduleEventType = get(data, 'eventType.code', null)
          this.teamOptions = teamOptions
          this.strikeZoneSettings = strikeZoneSettings
          this.doubleHeader = game.doubleHeader
          this.gameNumber = game.gameNumber

          if (bossState && !this._bossState) {
            this._bossState = bossState
          }
          if (sportId && this.store.isEventPage) {
            this.eventSportId = sportId
          }

          this.eventsThisAtBat = []
          Object.assign(this, gumboState)
        })
      )
      .catch((error) => {
        console.error(error)
      })
      .finally(
        action(() => {
          delete this._requests[id]

          clearTimeout(this.gumboStateTimeout)
          this.gumboStateTimeout = setTimeout(() => {
            this.fetchGumboState()
          }, 10 * 1000)
        })
      )
  }

  gumboStateTimeout = null
  fetchGumboState() {
    let { gamePk } = this

    if (!gamePk || this.store.isEventPage) {
      return clearTimeout(this.gumboStateTimeout)
    }

    fetch({
      url: '/api/game/gumboState',
      params: {
        gamePk,
      },
    })
      .then(
        action((data) => {
          let {
            awayRoster = [],
            homeRoster = [],
            gumboState = {},
            status = {},
            venue = {},
            strikeZoneSettings = {},
          } = data

          this.awayRoster.replace(awayRoster)
          this.homeRoster.replace(homeRoster)

          let {
            awayLineup = [],
            homeLineup = [],
            awayPositions = [],
            homePositions = [],
          } = gumboState

          if (this.gamedayLineupsEnabled) {
            if (this.awayLineup.some((id, i) => id != awayLineup[i])) {
              this.awayLineup.replace(awayLineup)
            }

            if (this.homeLineup.some((id, i) => id != homeLineup[i])) {
              this.homeLineup.replace(homeLineup)
            }

            if (this.awayPositions.some((id, i) => id != awayPositions[i])) {
              this.awayPositions.replace(awayPositions)
            }

            if (this.homePositions.some((id, i) => id != homePositions[i])) {
              this.homePositions.replace(homePositions)
            }
          }

          this.status = status
          this.strikeZoneSettings = strikeZoneSettings
          this.gumboState = gumboState
          this.venue = venue
        })
      )
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        clearTimeout(this.gumboStateTimeout)
        this.gumboStateTimeout = setTimeout(() => {
          this.fetchGumboState()
        }, 10 * 1000)
      })
  }

  get noradGameState() {
    let { username } = this.store.auth

    let {
      gamePk,
      balls,
      strikes,
      outs,
      inning,
      isTopInning,
      atBatNumber,
      pitchNumber,
      currentBatter = {},
      currentPitcher = {},
      lastPitchCall,
      gameStateLite,
      sessionId,
    } = this

    let { id: batterId, fullName: batterName } = currentBatter

    let { id: pitcherId, fullName: pitcherName } = currentPitcher

    let topInning = isTopInning ? 1 : 0

    return {
      gamePk,
      balls,
      strikes,
      outs,
      inning,
      topInning,
      atBatNumber,
      pitchNumber,
      lastPitchCall,
      batterId,
      batterName,
      pitcherId,
      pitcherName,
      username,
      statusIndicator: {
        ...gameStateLite,
        sessionId,
      },
    }
  }

  noradGameStateTimeout = null
  sendNoradGameState() {
    if (!this.isConnected) {
      return
    }

    let { noradGameState, gamePk } = this

    fetch({
      url: '/api/game/update',
      method: 'put',
      data: {
        gamePk,
        noradGameState,
      },
    })
      .then(
        action((connections) => {
          this.store.connections.replace(connections)

          if (!this.isConnected) {
            this.store.flash(NOTIFICATION_TYPES.SESSION_DISCONNECTED)
          }
        })
      )
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        if (this.noradGameStateTimeout) {
          clearTimeout(this.noradGameStateTimeout)
        }

        if (this.isConnected) {
          this.noradGameStateTimeout = setTimeout(() => {
            this.sendNoradGameState()
          }, 10 * 1000)
        }
      })
  }

  get shouldUpdatePitchcast() {
    return this.isConnected && !!this.trackingVersionId
  }

  updatePitchcastStrikezone() {
    if (!this.shouldUpdatePitchcast) {
      return
    }

    let { venueId, strikeZoneTop, strikeZoneBottom } = this

    let data = {
      strikeZoneTop,
      strikeZoneBottom,
    }

    if (!strikeZoneTop || !strikeZoneBottom || !venueId) {
      return
    }

    fetch({
      url: '/api/pitchcast/strikezone',
      method: 'post',
      data: {
        data,
        venueId,
      },
    })
      .then((command) => {
        // console.info(JSON.stringify(command, null, 4))
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  updatePitchcastMatchup() {
    if (!this.shouldUpdatePitchcast) {
      return
    }

    let {
      currentBatter = {},
      currentPitcher = {},
      battingTeam = {},
      fieldingTeam = {},
      venueId,
    } = this

    let { initLastName: pitcherName = '' } = currentPitcher

    let { initLastName: batterName = '', stats = {} } = currentBatter

    pitcherName = pitcherName.replace(' ', '. ')
    batterName = batterName.replace(' ', '. ')

    let { batting = {} } = stats

    let { hits, atBats } = batting

    let { id: batterTeamId } = battingTeam

    let { id: pitcherTeamId } = fieldingTeam

    let batterRecord = ''
    if (atBats) {
      batterRecord = `${hits} for ${atBats}`
    }

    let data = {
      pitcherName,
      batterName,
      batterRecord,
      pitcherTeamId,
      batterTeamId,
    }

    if (!pitcherName || !batterName || !batterTeamId || !pitcherTeamId) {
      return
    }

    fetch({
      url: '/api/pitchcast/matchup',
      method: 'post',
      data: {
        data,
        venueId,
      },
    })
      .then((command) => {
        // console.info(JSON.stringify(command, null, 4))
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  updatePitchcastPitchType(pitchType) {
    if (!this.shouldUpdatePitchcast) {
      return
    }

    let { gamePk, venueId } = this

    if (!venueId || !gamePk) {
      return
    }

    fetch({
      url: '/api/pitchcast/pitchType',
      method: 'post',
      data: {
        gamePk,
        venueId,
        pitchType,
      },
    })
      .then((command) => {
        // console.info(JSON.stringify(command, null, 4))
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  updatePitchcastPitchCount() {
    if (!this.shouldUpdatePitchcast) {
      return
    }

    let { venueId, pitchNumber, currentPitchCount: pitchCount } = this

    let data = {
      pitchCount,
      pitchNumber,
    }

    if (!venueId) {
      return
    }

    fetch({
      url: '/api/pitchcast/pitchCount',
      method: 'post',
      data: {
        venueId,
        data,
      },
    })
      .then((command) => {
        // console.info(JSON.stringify(command, null, 4))
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  _requests = {}

  get isLoading() {
    return Object.keys(this._requests).length > 0
  }

  get shouldFetch() {
    return (
      this.store.auth.isAuthenticated &&
      (this.store.isGamePage || this.store.isEventPage)
    )
  }

  get params() {
    const { gamePk, ngeAwayTeamId, ngeHomeTeamId } = this

    return {
      gamePk,
      ngeAwayTeamId: this.store.isEventPage ? ngeAwayTeamId : undefined,
      ngeHomeTeamId: this.store.isEventPage ? ngeHomeTeamId : undefined,
    }
  }

  get isGameOver() {
    let { outs, inning, scheduledInnings, isTopInning, runsHome, runsAway } =
      this

    let { statusCode = '' } = this.status || {}

    if (statusCode.startsWith('F') || statusCode.startsWith('O')) {
      return true
    }

    if (inning < scheduledInnings) {
      return false
    }

    if (isTopInning && outs === 3 && runsHome > runsAway) {
      return true
    }

    if (!isTopInning && runsHome > runsAway) {
      return true
    }

    if (!isTopInning && outs === 3 && runsHome != runsAway) {
      return true
    }

    return false
  }

  confirmWindowReload(e) {
    e.preventDefault()
    e.returnValue =
      'Are you sure you want to refresh the page? This will disconnect your game.'
    return e.returnValue
  }

  initialize() {
    if (this.shouldFetch) {
      this.fetch()
    }

    this.connectionReaction = reaction(
      () => this.isConnected,
      (isConnected) => {
        if (isConnected) {
          window.addEventListener('beforeunload', this.confirmWindowReload)
        } else {
          window.removeEventListener('beforeunload', this.confirmWindowReload)
        }
      }
    )

    // gamePk reaction
    this.paramsReaction = reaction(
      () => ({
        params: this.params,
        shouldFetch: this.shouldFetch,
      }),
      ({ params, shouldFetch }) => {
        /**
         * This gets fired whenever the gamePk changes in the url
         */

        this._isGameStarted = false

        /**
         * Clear timeouts
         */

        clearTimeout(this.endOfPlayTimeout)
        clearTimeout(this.gumboStateTimeout)
        clearTimeout(this.gameStateLiteHeartbeatTimeout)

        /**
         * Cancel requests
         */

        source.cancel()
        source = CancelToken.source()

        /**
         * Reset initial game state
         */

        this.merge(getInitialGameState())

        /**
         * Fetch game data if gamePk is present in the url
         */

        if (shouldFetch) {
          this.fetch()
        }
      }
    )

    this.bossStateReaction = reaction(
      () => ({
        bossState: this.bossState,
        isConnected: this.isConnected,
      }),
      ({ bossState, isConnected }) => {
        this.saveBossStateToLocalStorage()

        if (isConnected) {
          this.sendBossState()
        }
      }
    )

    this.gameStateLiteReaction = reaction(
      () => ({
        gameStateLite: this.gameStateLite,
        isConnected: this.isConnected,
      }),
      ({ gameStateLite, isConnected }) => {
        if (isConnected) {
          this.sendGameStateLite()
        }
      }
    )

    this.observerReaction = reaction(
      () => ({
        isObserving: this.isObserving,
        _bossState: this._bossState,
      }),
      ({ isObserving, _bossState }) => {
        if (isObserving && !this.isCorrecting) {
          this.setState(_bossState)
        }
      }
    )

    this.pageReaction = reaction(
      () => ({
        isGamePage: this.store.isGamePage,
        isEventPage: this.store.isEventPage,
      }),
      ({ isGamePage, isEventPage }) => {
        if (!isGamePage && !isEventPage) {
          this.set('isObserving', false)
          this.set('isCorrecting', false)
        }
      }
    )

    this.noradReaction = reaction(
      () => ({
        noradGameState: this.noradGameState,
        isConnected: this.isConnected,
      }),
      ({ noradGameState, isConnected }) => {
        if (isConnected) {
          this.sendNoradGameState()
        }
      }
    )

    this.matchupReaction = reaction(
      () => ({
        currentPitcher: this.currentPitcher,
        currentBatter: this.currentBatter,
        isConnected: this.isConnected,
      }),
      ({ currentPitcher, currentBatter, isConnected }) => {
        if (isConnected) {
          this.updatePitchcastMatchup()
        }
      }
    )

    this.strikeZoneReaction = reaction(
      () => ({
        currentBatter: this.currentBatter,
        isConnected: this.isConnected,
      }),
      ({ currentBatter, isConnected }) => {
        if (isConnected) {
          this.updatePitchcastStrikezone()
        }
      }
    )

    this.pitchCount = reaction(
      () => ({
        currentPitchCount: this.currentPitchCount,
        pitchNumber: this.pitchNumber,
        isConnected: this.isConnected,
      }),
      ({ currentPitchCount, pitchNumber, isConnected }) => {
        if (isConnected) {
          this.updatePitchcastPitchCount()
        }
      }
    )

    this.gameModeReaction = reaction(
      () => ({
        status: this.status,
      }),
      ({ status }) => {
        if (this.gameMode == null && status && status.statusCode) {
          if (['P', 'S'].includes(status.statusCode)) {
            this.set('gameMode', 0)
          } else {
            this.set('gameMode', 1)
          }
        } else if (
          this.gameMode == 0 &&
          status &&
          !['P', 'S'].includes(status.statusCode)
        ) {
          this.set('gameMode', 1)
        }
      }
    )

    this.nextFrameHotkeyReaction = reaction(
      () => ({
        outs: this.outs,
        isGameOver: this.isGameOver,
      }),
      ({ outs, isGameOver }) => {
        if (outs < 3 || isGameOver) {
          this._nextFrameHotkeyEnabled = false
        } else {
          this._nextFrameHotkeyEnabled = true
        }
      }
    )

    this.currentBatterIdReaction = reaction(
      () => ({
        currentBatterId: this.currentBatterId,
        currentPitcherId: this.currentPitcherId,
      }),
      ({ currentBatterId, currentPitcherId }) => {
        if (this.currentBatter) {
          let batSide = this.currentBatter.bats
          if (batSide == 'S' && this.currentPitcher) {
            batSide = this.currentPitcher.throws == 'R' ? 'L' : 'R'
          }

          this.currentBatSide = batSide
        }
      }
    )

    this.ngeTeamReaction = reaction(
      () => ({
        awayTeam: this.awayTeam,
        homeTeam: this.homeTeam,
      }),
      ({ awayTeam, homeTeam }) => {
        if (this.store.isEventPage) {
          if (awayTeam && awayTeam.id && !this.ngeAwayTeamId) {
            this.ngeAwayTeamId = awayTeam.id
          }
          if (homeTeam && homeTeam.id && !this.ngeHomeTeamId) {
            this.ngeHomeTeamId = homeTeam.id
          }
        }
      }
    )

    this.ngeTeamSelectReaction = reaction(
      () => ({
        ngeAwayTeamId: this.ngeAwayTeamId,
        ngeHomeTeamId: this.ngeHomeTeamId,
      }),
      ({ ngeAwayTeamId, ngeHomeTeamId }) => {
        if (this.store.isEventPage) {
          debounce(() => {
            this.fetch()
          }, 50)
        }
      }
    )
  }
}

decorate(GameStore, {
  disconnect: action,
  gamePk: computed,
  gamedayLineupsEnabled: observable,

  inning: observable,
  scheduledInnings: observable,
  linescore: observable,
  balls: observable,
  strikes: observable,
  outs: observable,
  atBatNumber: observable,
  homePitchCount: observable,
  awayPitchCount: observable,
  hitsHome: observable,
  errorsHome: observable,
  hitsAway: observable,
  errorsAway: observable,
  isTopInning: observable,
  eventsThisAtBat: observable,
  baseRunners: observable,
  pitcherOnRubber: observable,
  pitchNumber: observable,
  pickoffNumber: observable,
  stringerAtBatNumber: computed,
  stringerPitchNumber: computed,

  runsAway: computed,
  runsHome: computed,

  awayTeam: observable,
  homeTeam: observable,

  awayPitcherId: observable,
  homePitcherId: observable,
  currentPitcherId: computed,
  awayPitcher: computed,
  homePitcher: computed,
  currentPitcher: computed,
  battingPitcher: computed,
  fieldingPitcher: computed,
  visiblePitcher: computed,
  visiblePitcherId: computed,
  showBattingTeamPitcher: observable,
  setPitcher: action,
  setVisiblePitcher: action,
  toggleVisiblePitcher: action,

  awayBatterIdx: observable,
  homeBatterIdx: observable,
  battingBatterIdx: computed,
  fieldingBatterIdx: computed,
  visibleBatterIdx: computed,

  awayBatterId: computed,
  homeBatterId: computed,
  currentBatterId: computed,
  awayBatter: computed,
  homeBatter: computed,
  currentBatter: computed,
  strikeZoneTop: computed,
  strikeZoneBottom: computed,
  currentBatSide: observable,

  strikeZoneSettings: observable,

  /*
   *   Boss Actions
   */

  // Events

  resetEvents: action,

  // Bases

  toggleBaseRunner: action,
  toggleRubber: action,

  // Count

  resetCount: action,
  setAtBats: action,

  // Lineup

  _nextBatterHotkeyEnabled: observable,
  incrementBatterIdx: action,
  decrementBatterIdx: action,

  _nextFrameHotkeyEnabled: observable,
  incrementInning: action,
  decrementInning: action,

  _incrementRunsHotkeyEnabled: observable,

  awayLineup: observable,
  homeLineup: observable,
  battingLineup: computed,
  fieldingLineup: computed,
  visibleLineup: computed,
  visibleLineupPlayers: computed,

  awayRoster: observable,
  homeRoster: observable,
  battingRoster: computed,
  fieldingRoster: computed,
  visibleRoster: computed,

  awayPositions: observable,
  homePositions: observable,
  battingPositions: computed,
  fieldingPositions: computed,
  visiblePositions: computed,

  _requests: observable,
  isLoading: computed,
  params: computed,
  shouldFetch: computed,
  fetch: action,

  venue: observable,
  venueId: computed,
  trackingVersionId: computed,

  shouldUpdatePitchcast: computed,

  gameType: observable,
  status: observable,

  datetime: observable,
  dayNight: computed,

  swapPlayers: action,
  playerMap: computed,

  showDefense: observable,
  toggleDefense: action,

  handleClick: action,
  setPitchCount: action,

  pitchData: observable,

  bossState: computed,
  _bossState: observable,
  gumboState: observable,
  gameStateLite: computed,
  set: action,
  setState: action,
  merge: action,

  isObserving: observable,
  isCorrecting: observable,
  isConnected: computed,

  noradGameState: computed,
  isTimeout: observable,
  toggleTimeout: action,
  toggleCorrection: action,
  isCorrection: observable,
  runnerMovement: observable,
  toggleMovement: action,

  triggerEvent: action,
  lastEvent: observable,
  previousLastEvent: observable,

  isGameOver: computed,
  gameMode: observable,

  cancelCorrections: action,
  sendCorrections: action,

  canObserve: computed,
  canCorrect: computed,

  eventName: observable,
  isPrivate: observable,
  scheduleEventType: observable,

  addPlayerToLineup: action,
  removePlayerFromLineup: action,

  isMlbGame: computed,
  isSpringTrainingGame: computed,
  isExhibitionGame: computed,
  hasFlexibleLineups: computed,
  teamOptions: observable,
  ngeAwayTeamId: observable,
  ngeHomeTeamId: observable,
  triggerHomeRun: action,
  incrementRuns: action,
  decrementRuns: action,
  incrementHits: action,
  decrementHits: action,

  _homeRunButtonEnabled: observable,
  _incrementHitsHotkeyEnabled: observable,

  doubleHeader: observable,
  gameNumber: observable,
})
